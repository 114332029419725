.p-chat-room .p-chat-button {
  width: 1rem;
  height: 0.59rem;
  border-radius: 0.1rem;
  line-height: normal;
  color: #fff;
  font-size: 0.24rem;
  background-color: #59b6d7;
}

.p-chat-room .p-chat-button[disabled] {
  background-color: #ccc;
}

.p-chat-room-main {
  padding: 0.2rem;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.p-chat-room-main .message-item + .message-item {
  margin-top: 0.3rem;
}

.p-chat-room-foot {
  line-height: 1;
  overflow: hidden;
  padding: 0.1rem;
}

.p-chat-input {
  position: relative;
  overflow: hidden;
  padding: 0.1rem;
  margin-right: 0.3rem;
  border-radius: 0.1rem;
  border: 1px solid #ededed;
}

.p-chat-input textarea {
  font-size: 0.26rem;
  line-height: 1.5;
  width: 100%;
  resize: none;
  max-height: 1.76rem;
  -webkit-overflow-scrolling: touch;
}

.p-chat-input .shadow-input {
  border-radius: 0;
  padding: 0;
  position: absolute;
  width: 100%;
  z-index: -9999;
  visibility: hidden;
}