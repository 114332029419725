.msg-bubble-main {
  margin: 0 0.2rem;
  max-width: 5.2rem;
}

.msg-bubble-user-name {
  font-size: 0.26rem;
  color: #999;
  line-height: 1;
  margin-bottom: 0.15rem;
  margin-top: 0.15rem;
}

.msg-bubble-body {
  padding: 0.2rem;
  background-color: #f7f7f7;
  border: 1px solid #dee1e2;
  border-radius: 0 0.2rem 0.2rem;
}

.msg-bubble.selef {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.msg-bubble.selef .msg-bubble-main {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.msg-bubble.selef .msg-bubble-body {
  border-radius: 0.2rem 0 0.2rem 0.2rem;
  border-color: #87c6dd;
  background-color: #b3e1f2;
}

.msg-bubble .m-avatar-box {
  margin: inherit;
}

.room-tips {
  margin-left: 0.96rem;
  margin-right: 0.96rem;
  text-align: center;
}

.room-tips span {
  display: inline-block;
  padding: 0.05rem 0.15rem;
  color: #fff;
  font-size: 0.2rem;
  line-height: 1.5;
  border-radius: 0.2rem;
  background-color: #d9d9d9;
}