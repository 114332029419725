.c-reference-news {
  padding: 0.15rem 0.2rem;
  background-color: #f4f5f5;
  font-size: 0.26rem;
}

.c-reference-news > a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #999;
}

.c-reference-news .image {
  width: 0.8rem;
  height: 0.8rem;
  margin-right: 0.15rem;
}

.c-reference-news .image > div {
  width: 100%;
  height: 100%;
  background: no-repeat center / cover;
}