.p-message-home .message-list {
  background-color: #fff;
  font-size: 0.28rem;
}

.p-message-home .message-list > li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.15rem;
  border-bottom: 0.01rem solid #ededed;
}

.p-message-home .message-list > li .m-svg-large {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  width: 0.9rem;
  height: 0.9rem;
  margin-right: 0.15rem;
}

.p-message-home .message-list .info {
  -webkit-box-flex: 1;
  -ms-flex: auto;
  flex: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.p-message-home .message-list .info h2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.p-message-home .message-list .info p {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.p-message-home .message-list .info .time,
.p-message-home .message-list .info .description {
  color: #999;
}