/**** msg页面 ****/

.msg .entry__item {
  min-height: 1.36rem;
  padding: 0.28rem 0;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.msg .entry__item .entry__item--prepend {
  width: 0.5rem;
  height: 0.5rem;
  fill: #fff;
}

.msg .entry__item div:first-child {
  width: 0.76rem;
  height: 0.76rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.msg-users {
  width: 4.8rem;
}

.msg-users h5 {
  font-size: 0.32rem;
  margin: 0;
  color: #333;
  font-weight: 400;
}

.msg-users p {
  font-size: 0.24rem;
  margin: 0.12rem 0 0 0;
  color: #b4b4b4;
}

.msg-time {
  text-align: right;
  width: 1.2rem;
  font-size: 0.24rem;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.msg-time-count {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 0.24rem;
  height: 0.32rem;
  min-width: 0.32rem;
  color: #fff;
  border-radius: 0.16rem;
  background-color: #f76c69;
}

.msg-time-count i {
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
}

.msg-time h5 {
  margin: 0;
  color: #ccc;
  font-weight: 400;
  text-align: right;
  font-size: 0.24rem;
  height: 0.32rem;
}

/**** 评论列表页 ****/

.msgList {
  font-size: 0.24rem;
}

.msgList-item {
  padding: 0.2rem;
  border-bottom: #f6f6f6 1px solid;
  background-color: #fff;
}

.msgList-item .red {
  color: #f00;
  font-size: 0.24rem;
  margin-top: 0.1rem;
}

.msgList-item .green {
  color: green;
  font-size: 0.24rem;
  margin-top: 0.1rem;
}

.msgList-item .gray {
  color: #999;
  font-size: 0.24rem;
  margin-top: 0.1rem;
}

.msgList-item span.content {
  display: inline-block;
  margin-bottom: 0.15rem;
  color: #333;
  font-size: 0.3rem;
}

.msgList-item div.content {
  color: #333;
}

.msgList-item div.content.red {
  color: #f00;
}

.msgList-item div.content span {
  color: #999;
}

.msgList-item .content {
  text-align: initial;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-all;
  line-height: normal;
  color: #999;
  width: -webkit-calc(100% - 1rem);
  width: calc(100% - 1rem);
  line-height: 0.4rem;
}

.msgList-item-bottom {
  padding-left: 0.97rem;
  padding-top: 0.1rem;
}

.msgList-item-bottom-img {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.msgList-item-bottom-img div.img {
  width: 0.6rem;
  height: 0.6rem;
  margin-right: 0.1rem;
  font-size: 0;
}

.msgList-item-bottom-img div.img img {
  width: 0.6rem;
  height: 0.6rem;
  -o-object-fit: cover;
  object-fit: cover;
}

.msgList-item-bottom section:last-child {
  background-color: #f7f7f7;
  padding: 0.11rem;
}

.msgList-item-top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  color: #999;
  font-size: 0.28rem;
}

.msgList-item-top-link {
  color: #333;
}

.msgList-item-top section.gray,
.msgList-item-top section.red,
.msgList-item-top section.green {
  margin: 0;
  text-align: right;
}

.msgList-item-top section.gray > span,
.msgList-item-top section.red > span,
.msgList-item-top section.green > span {
  display: inline-block;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.msgList-item-top section.userInfo {
  -webkit-box-flex: 1;
  -ms-flex: auto;
  flex: auto;
  width: 3.5rem;
  margin-left: 0.2rem;
  margin-right: 0.2rem;
  font-size: 0.26rem;
}

.msgList-item-top section p {
  margin: 0.11rem 0 0;
  color: #ccc;
  font-size: 0.24rem;
}

.msgList-status {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  min-width: 0;
}

.amount-show {
  margin-right: 0.2rem;
}

.reply {
  margin-right: 0;
  background-color: #f3f4f4;
  padding: 0.1rem 0.15rem;
}

.audit-show {
  margin-right: 0.2rem;
  color: #fca308;
}

.audit-operation {
  margin-right: 0;
  color: #4bb893;
  border: 1px solid #4bb893;
  border-radius: 0.2rem;
  padding: 0 0.2rem;
  font-size: 0.22rem;
}

.gray span {
  margin-right: 0.3rem;
}