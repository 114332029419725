.c-reference-feed {
  padding: 0.15rem 0.2rem;
  background-color: #f4f5f5;
  color: #999;
  font-size: 0.26rem;
}

.c-reference-feed > .content {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  display: -webkit-box-flex;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.c-reference-feed > a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #999;
}

.c-reference-feed .image {
  width: 0.8rem;
  height: 0.8rem;
  margin-right: 0.15rem;
}

.c-reference-feed .image > div {
  width: 100%;
  height: 100%;
  background: no-repeat center / cover;
}

.c-reference-feed .video {
  position: relative;
}

.c-reference-feed .video::after,
.c-reference-feed .video::before {
  content: '';
  position: absolute;
  display: block;
}

.c-reference-feed .video::after {
  left: 0.1rem;
  top: 0.1rem;
  width: 0.6rem;
  height: 0.6rem;
  border: 0.01rem solid #fff;
  border-radius: 0.3rem;
}

.c-reference-feed .video::before {
  left: 0.35rem;
  top: 0.25rem;
  border: 0.3rem solid transparent;
  border-width: 0.15rem 0.25rem;
  border-left-color: #fff;
}