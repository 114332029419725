.c-chat-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 1.35rem;
  padding: 0.3rem 0.2rem;
  border-bottom: 1px solid #ededed;
  background-color: #fff;
}

.c-chat-item .main {
  -webkit-box-flex: 1;
  -ms-flex: auto;
  flex: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  margin: 0 0.3rem;
  font-size: 0.28rem;
  color: #999;
  overflow: hidden;
}

.c-chat-item .main h2 {
  font-size: 0.32rem;
  color: #333;
}

.c-chat-item .main h2 .title {
  display: inline-block;
  max-width: 70%;
  vertical-align: middle;
}

.c-chat-item .ext {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  height: 100%;
  font-size: 0.24rem;
  color: #ccc;
}

.c-chat-item .ext .count {
  -ms-flex-item-align: end;
  align-self: flex-end;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 0.24rem;
  height: 0.32rem;
  width: 0.32rem;
  color: #fff;
  border-radius: 0.16rem;
  background-color: #f76c69;
}