.c-message-comments-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.c-message-comments-item .m-avatar-box {
  margin-right: 0.15rem;
}

.c-message-comments-item > main {
  -webkit-box-flex: 1;
  -ms-flex: auto;
  flex: auto;
}

.c-message-comments-item .sender-info {
  -webkit-box-flex: 1;
  -ms-flex: auto;
  flex: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  margin-top: 0.1rem;
  margin-bottom: 0.15rem;
}

.c-message-comments-item .sender-info .user-info {
  margin-top: 0.1rem;
  font-size: 0.28rem;
  color: #ccc;
}

.c-message-comments-item .sender-info .user-info .username {
  color: #1A1A1A;
  margin-right: 0.08rem;
}

.c-message-comments-item .sender-info .reply {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  font-size: 0.26rem;
  color: #999;
  padding: 0.06rem 0.15rem;
}

.c-message-comments-item .sender-info .time {
  font-size: 0.26rem;
  color: #ccc;
}

.c-message-comments-item .comment-item-bottom .content span {
  color: #59b6d7;
  margin-right: 0.1rem;
}