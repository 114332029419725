.p-message-system .notification-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.3rem;
  border-bottom: 0.01rem solid #ededed;
  background-color: #fff;
}

.p-message-system .notification-item h5 {
  color: #333;
  font-size: 0.28rem;
  font-weight: 400;
}

.p-message-system .notification-item p {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  margin-left: 0.3rem;
  color: #999;
  font-size: 0.24rem;
}